<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo auth-page">
        <logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 overflow-hidden bg-white"
        :style="`background-image: url(${imgUrl})`"
      />
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('shared.login') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('login.welcome') }} {{ companyName }}
          </b-card-text>

          <div v-if="isTwoFactorAuthenticationInProgress">
            <!-- authCode -->
            <b-form-group
              :label="$t('shared.auth-code')"
              label-for="login-auth-code"
            >
              <b-form-input
                id="login-auth-code"
                v-model="authCode"
                name="login-auth-code"
                type="text"
                placeholder="000000"
                maxlength="6"
                autocomplete="off"
                @input="verifyAuthCode"
              />
            </b-form-group>

          </div>

          <div v-else>
            <!-- form -->
            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
              >
                <!-- email -->
                <b-form-group
                  :label="$t('shared.username')"
                  label-for="login-username"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('shared.username')"
                    rules="required|alpha-num"
                  >
                    <b-form-input
                      id="login-username"
                      v-model="username"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      :placeholder="$t('shared.username')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">{{ $t('shared.password') }}</label>
                  <!--                  <b-link :to="{name:'auth-forgot-password-v2'}">-->
                  <!--                    <small>Forgot Password?</small>-->
                  <!--                  </b-link>-->
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('shared.password')"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="loading"
                  @click="login"
                >
                  <b-spinner
                    v-show="loading"
                    small
                  />
                  {{ $t('shared.login') }}
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core-override/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { required, alphaNum } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import BeamsClient from '@/libs/beams'
// import store from '@/store/store'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    BSpinner,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      authCode: '',
      loading: true,
      sideImg: require('@/assets/images/pages/login/bg.png'),
      required,
      alphaNum,
    }
  },
  computed: {
    companyName() {
      return this.$store.state.appConfig.organization.info.shortName
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
      //   return this.sideImg
      // }
      return this.sideImg
    },
    isTwoFactorAuthenticationInProgress() {
      return this.$store.getters['auth/isTwoFactorAuthenticationInProgress']
    },
  },
  beforeMount() {
    BeamsClient.clearBeamsDeviceInterests()
    this.isUserLoggedIn()
  },
  methods: {
    isUserLoggedIn() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$router.push('/')
        return true
      }
      this.loading = false
      return false
    },
    login() {
      if (this.isUserLoggedIn()) {
        return
      }

      this.loading = true
      this.$refs.loginValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }
          const payload = {
            userDetails: {
              username: this.username,
              password: this.password,
            },
          }

          this.$store.dispatch('auth/login', payload)
            .then(res => {
              if (res.data.token) {
                this.getUserInfo()
              }
            })
            .catch(() => {
              this.loading = false
              window.toast.notify.danger(this.$t('login.wrong_data'))
            })

          return true
        })
    },
    verifyAuthCode() {
      if (this.authCode.length !== 6) {
        return
      }

      const payload = {
        authCode: this.authCode,
        addDeviceToTrusted: true,
      }

      this.$store.dispatch('auth/twoFactorCheck', payload)
        .then(res => {
          if (res.data.token) {
            this.getUserInfo()
          }
          if (res.data.login === 'failed') {
            window.toast.notify.danger(this.$t(res.data.message ?? 'login.wrong_data'))
          }
        })
        .catch(() => {
          this.loading = false
          window.toast.notify.danger(this.$t('login.wrong_data'))
        })
    },
    getUserInfo() {
      Promise.all([
        this.$store.dispatch('auth/fetchUserInfo'),
        this.$store.dispatch('auth/fetchUserTeam'),
      ])
        .then(() => {
          const prevRoute = localStorage.getItem('previousPath')
          if (prevRoute) {
            localStorage.removeItem('previousPath')
            this.$router.push(prevRoute)
          } else {
            this.$router.push({ name: 'dashboard' })
          }
        })
        .catch(err => {
          window.toast.notify.danger(err.message)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
